<template>
  <div class="order-detail" v-if="item">
    <van-nav-bar title="订单详情" left-arrow fixed @click-left="$router.goBack()" />
    <div class="content" :class="{'maginTop': true}">
      <div class="detail-header">
        <div class="header-info1">{{item.consignee}} {{item.phone}}</div>
        <div class="header-info2">{{item.address}}</div>
      </div>
      <div class="item-info">
        <div class="item-img">
          <img :src="item.mainImg" />
        </div>
        <div class="item-content">
          <div class="item-name">{{item.productName}}</div>
          <!-- <div class="item-text">{{item.productName}}</div> -->
          <div class="item-count">数量：{{item.quantity}}</div>
          <div class="item-price">￥ {{item.amount}}</div>
        </div>
        <div class="item-state">{{getStatusText(item.status)}}</div>
      </div>
      <div class="other-info">
        <div class="info-item">
          <div class="left-text">配送方式</div>
          <div class="right-text">
            <span v-if="item.deliveryType === 1">快递</span>
            <span v-if="item.deliveryType === 2">自提</span>
          </div>
        </div>
        <div class="info-item">
          <div class="left-text">运费</div>
          <div class="right-text">到付</div>
        </div>
        <div class="info-item">
          <div class="left-text">付款方式</div>
          <div class="right-text">{{getPayTypeText(item.payType)}}</div>
        </div>
        <div class="info-item">
          <div class="left-text">实付款</div>
          <div class="right-text">￥ {{item.payAmt}}</div>
        </div>
        <!-- <div class="info-item">
          <div class="left-text">终端数据</div>
          <div class="right-text">5个</div>
        </div>-->
        <div class="divider"></div>
        <div class="info-normal">
          <div class="left-text">订单备注</div>
          <div class="right-text">{{item.note}}</div>
        </div>
        <div class="info-normal">
          <div class="left-text">订单号</div>
          <div class="right-text">{{item.orderNo}}</div>
          <div class="copy-btn" @click="copyContent(item.orderNo)">复制</div>
        </div>
        <div class="info-normal">
          <div class="left-text">创建时间</div>
          <div class="right-text">{{item.createdTime}}</div>
        </div>
        <div class="info-normal">
          <div class="left-text">付款时间</div>
          <div class="right-text">{{item.payTime}}</div>
        </div>
        <div class="info-normal">
          <div class="left-text">发货时间</div>
          <div
            class="right-text"
          >{{item.status === 3 || item.status === 4 ? item.expressDate : "未发货"}}</div>
        </div>
        <div class="info-normal">
          <div class="left-text">快递公司</div>
          <div class="right-text">{{item.expressName}}</div>
          <div class="copy-btn" v-if="item.expressName" @click="copyContent(item.expressName)">复制</div>
        </div>
        <div class="info-normal">
          <div class="left-text">快递单号</div>
          <div class="right-text">{{item.expressNo}}</div>
          <div class="copy-btn" v-if="item.expressNo" @click="copyContent(item.expressNo)">复制</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { orderDetail, getStatusText, getPayTypeText } from "@/api/orders";
export default {
  name: "OrderDetail",
  data() {
    return {
      item: null,
      getStatusText: getStatusText,
      getPayTypeText: getPayTypeText
    };
  },
  async created() {
    const { orderNo } = this.$route.query;
    if (!orderNo) this.$router.goBack(true);
    const { code, data, msg } = await orderDetail(orderNo);
    if (code !== 0) {
      Toast(msg);
      this.$router.goBack(true);
      return;
    }
    this.item = data;
  },
  methods: {
    copyContent(content) {
      // window.clipboardData.setData("Text", content);
      const url = content;
      const oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      // console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      Toast("复制成功");
    }
  }
};
</script>

<style lang="scss">
.order-detail {
  .content {
    // padding-top: 46px;
    background: #f8f8f8;
  }
  .divider {
    height: 15px;
  }
  .detail-header {
    padding: 15px 20px;
    background: #fff;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    .header-info1 {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .header-info2 {
      font-size: 13px;
      color: #666;
    }
  }
  .item-info {
    position: relative;
    display: flex;
    padding: 15px 10px 15px 20px;
    background: #fff;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    .item-img {
      img {
        width: 80px;
        height: 80px;
      }
    }
    .item-content {
      position: relative;
      margin-left: 15px;
      max-width: calc(100vw - 190px);
      font-size: 13px;
      color: #888;
      .item-name {
        color: black;
        font-weight: bold;
        font-size: 16px;
      }
      .item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .item-count {
      }

      .item-price {
      }
    }
    .item-state {
      position: absolute;
      // top: 0;
      right: 30px;
      // margin-left: 20px;
      color: #fc6037;
      font-size: 14px;
      // width: 70px;
      text-align: center;
    }
  }
  .other-info {
    background: #fff;
  }
  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
    font-size: 14px;
    .left-text {
      color: #999;
    }
  }
  .divider {
    height: 10px;
    background: #f8f8f8;
  }
  .info-normal {
    display: flex;
    align-items: center;
    margin: 0 15px;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
    font-size: 14px;
    .left-text {
      width: 56px;
      color: #999;
    }
    .right-text {
      margin-left: 10px;
    }
    .copy-btn {
      margin-left: 20px;
      color: #fc6037;
    }
  }
}
</style>